<script>
import {mapState} from "vuex";

/**
 * Profile component
 */
export default {
  computed: {
    ...mapState({
      authState: state => state.auth
    }),
  },
  data() {
    return {
      orgName: process.env.VUE_APP_ORG_NAME
    };
  },
}
</script>
<template>
  <div class="card overflow-hidden">
    <div class="bg-soft-primary">
      <div class="row">
        <div class="col-6">
          <div class="text-primary p-3">
            <h5 class="text-primary">Witaj {{ authState.user.firstName }}!</h5>
            <p>Co dobrego dzisiaj zrobimy?</p>
          </div>
        </div>
        <div class="col-6 align-self-end">
          <img src="@/assets/images/vector_volunteers0.png" alt class="img-fluid"/>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-sm-7">
          <div class="avatar-md profile-user-wid mb-4">

            <img v-if="authState.user.profileImageUrl.length > 0"
                 class="img-thumbnail rounded-circle"
                 v-bind:src="authState.user.profileImageUrl"
                 alt="Header Avatar"
            />
            <img v-if="authState.user.profileImageUrl.length == 0"
                 class="img-thumbnail rounded-circle"
                 src="@/assets/images/users/avatar-1.jpg"
                 alt="Header Avatar"
            />

          </div>
          <h5 class="font-size-15 text-truncate">{{ authState.user.firstName }} {{ authState.user.lastName }}</h5>
          <p class="text-muted mb-0">{{ authState.user.position }}</p>
        </div>

        <div class="col-sm-5">
          <div class="pt-4">
            <div class="row">
              <div class="col-12">
                <h5 class="font-size-15"></h5>
                <p class="text-muted mb-0">{{ orgName }}</p>
              </div>
            </div>
            <div class="mt-4">
              <router-link :to="{ name: 'Profil pracownika', params: { id: authState.user.uuid } }"
                           class="btn btn-primary waves-effect waves-light btn-sm">
                Zobacz swój Profil
                <i class="mdi mdi-arrow-right ml-1"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
