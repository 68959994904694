<script>
import {mapActions, mapGetters, mapState} from "vuex";

import Layout from "../layouts/main";
import Preloader from "@/components/widgets/preloader";
import Profile from "@/components/account/account-profile-logged";
import Stat from "@/components/widgets/stat";
import DashboardCharts from "@/components/widgets/stats-charts";
import Repository from "../../app/repository/repository-factory";

const SubaccountDashboardRepository = Repository.get("SubaccountDashboardRepository");

/**
 * Dashboard-1 Component
 */
export default {
  components: {Layout, Profile, Stat, DashboardCharts, Preloader},
  data() {
    return {
      title: "Dashboard",
      preloader: true,
      facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
      linkedinUrl: process.env.VUE_APP_LINKEDIN_URL,
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      statData: [
        {
          icon: "bx bx-copy-alt",
          title: "",
          value: ""
        },
        {
          icon: "bx bx-archive-in",
          title: "",
          value: ""
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "",
          value: ""
        }
      ],
    };
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      users: state => state.users.all
    }),
  },
  mounted() {
    this.preloader = false;
  },
  created() {
    this.getCountSubaccountAndSummarySumOperationsAmount();
  },
  methods: {
    getCountSubaccountAndSummarySumOperationsAmount() {
      SubaccountDashboardRepository.getCountSubaccountAndSummarySumOperationsAmount().then((response) => {
        this.stats = {'categories': response.data.labels};
        this.statData = [
          {
            icon: "bx bx-user-plus",
            title: "Podopieczni",
            value: response.data['countSubaccount']
          },
          {
            icon: "bx bx-sort-up",
            title: "Zebrane środki",
            value: response.data['payIn'] + ' zł'
          },
          {
            icon: "bx bx-sort-down",
            title: "Przekazane środki",
            value: response.data['payOut'] + ' zł'
          }
        ]

      }).catch(error => {
        console.log(error);
      })
    },
    ...mapGetters("auth", {
      getUser: "getUser"
    }),
    ...mapActions("users", {
      getAllUsers: "getAll",
      deleteUser: "delete"
    })
  }
};
</script>

<template>
  <Layout>
    <preloader v-if="preloader"></preloader>

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active"></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-4">

        <Profile/>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Nasze media społecznościowe</h4>
            <div class="text-center">
              <a :href="facebookUrl">
                <div class="avatar-sm mx-auto mb-4">
                  <span class="avatar-title rounded-circle bg-primary font-size-18">
                    <i class="mdi mdi-facebook text-white"></i>
                  </span>
                </div>
                <p class="font-16 text-muted mb-2"></p>
                <h5>Facebook</h5>
              </a>
            </div>
            <div class="row mt-4">
              <div class="col-sm-4">
                <a :href="linkedinUrl">
                  <div class="social-source text-center mt-3">
                    <div class="avatar-xs mx-auto mb-3">
                      <span class="avatar-title rounded-circle bg-primary font-size-16">
                        <i class="mdi mdi-linkedin text-white"></i>
                      </span>
                    </div>
                    <h5 class="font-size-15">LinkedIn</h5>
                  </div>
                </a>
              </div>
              <div class="col-sm-4">
                <div class="social-source text-center mt-3">
                  <a :href="instagramUrl">
                    <div class="avatar-xs mx-auto mb-3">
                      <span class="avatar-title rounded-circle bg-pink font-size-16">
                        <i class="mdi mdi-instagram text-white"></i>
                      </span>
                    </div>
                    <h5 class="font-size-15">Instagram</h5>
                  </a>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="social-source text-center mt-3">
                  <a :href="twitterUrl">
                  <div class="avatar-xs mx-auto mb-3">
                    <span class="avatar-title rounded-circle bg-info font-size-16">
                      <i class="mdi mdi-twitter text-white"></i>
                    </span>
                  </div>
                  <h5 class="font-size-15">Twitter</h5>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
      <div class="col-xl-8">
        <div class="row">
          <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
            <Stat :icon="stat.icon" :title="stat.title" :value="stat.value"/>
          </div>
        </div>
        <!-- end row -->

        <!-- sssssssssssssssssss -->
        <DashboardCharts/>
      </div>
    </div>
    <!-- end row -->

  </Layout>
</template>
